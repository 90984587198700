:root {
  --primary-0: #000000;
  --primary-5: #041405;
  --primary-10: #092009;
  --primary-15: #0f2c0f;
  --primary-20: #173816;
  --primary-25: #224321;
  --primary-30: #2d4e2b;
  --primary-35: #395a35;
  --primary-40: #446740;
  --primary-50: #5c8057;
  --primary-60: #759b6f;
  --primary-70: #8fb688;
  --primary-80: #a9d2a1;
  --primary-90: #c5eebc;
  --primary-95: #d3fdc9;
  --primary-98: #effee8;
  --primary-99: #f8fef1;
  --primary-100: #ffffff;

  --secondary-0: #000000;
  --secondary-5: #091308;
  --secondary-10: #131e11;
  --secondary-15: #1d281b;
  --secondary-20: #273325;
  --secondary-25: #323e2f;
  --secondary-30: #3d4a3a;
  --secondary-35: #495646;
  --secondary-40: #556251;
  --secondary-50: #6d7b69;
  --secondary-60: #879582;
  --secondary-70: #a1af9c;
  --secondary-80: #bdcbb6;
  --secondary-90: #d9e7d2;
  --secondary-95: #e6f5df;
  --secondary-98: #effee8;
  --secondary-99: #f8fef1;
  --secondary-100: #ffffff;

  --tertiary-0: #000000;
  --tertiary-5: #041416;
  --tertiary-10: #081e22;
  --tertiary-15: #0d2a2e;
  --tertiary-20: #13353b;
  --tertiary-25: #1f4146;
  --tertiary-30: #2b4c52;
  --tertiary-35: #37585e;
  --tertiary-40: #43646a;
  --tertiary-50: #5b7d84;
  --tertiary-60: #75979e;
  --tertiary-70: #8fb2b9;
  --tertiary-80: #aacdd4;
  --tertiary-90: #c5eaf1;
  --tertiary-95: #d5f7fe;
  --tertiary-98: #effbfe;
  --tertiary-99: #f7feff;
  --tertiary-100: #ffffff;

  --neutral-0: #000000;
  --neutral-5: #0f120d;
  --neutral-10: #191d17;
  --neutral-15: #242721;
  --neutral-20: #2e322c;
  --neutral-25: #393d37;
  --neutral-30: #444842;
  --neutral-35: #50544e;
  --neutral-40: #5c605a;
  --neutral-50: #757871;
  --neutral-60: #8f928b;
  --neutral-70: #a9aca5;
  --neutral-80: #c5c8c0;
  --neutral-90: #e1e4dc;
  --neutral-95: #eff2ea;
  --neutral-98: #f8fbf3;
  --neutral-99: #fbfef5;
  --neutral-100: #ffffff;

  --neutral-variant-0: #000000;
  --neutral-variant-5: #0d120c;
  --neutral-variant-10: #181d17;
  --neutral-variant-15: #222721;
  --neutral-variant-20: #2d322b;
  --neutral-variant-25: #383d36;
  --neutral-variant-30: #434941;
  --neutral-variant-35: #4e544c;
  --neutral-variant-40: #5a6058;
  --neutral-variant-50: #737970;
  --neutral-variant-60: #8d9389;
  --neutral-variant-70: #a7ada3;
  --neutral-variant-80: #c3c8be;
  --neutral-variant-90: #dfe5da;
  --neutral-variant-95: #edf3e8;
  --neutral-variant-98: #f6fbf0;
  --neutral-variant-99: #f9fef3;
  --neutral-variant-100: #ffffff;

  --error-0: #000000;
  --error-5: #280302;
  --error-10: #3b0605;
  --error-15: #3b0605;
  --error-20: #4d0a08;
  --error-25: #731411;
  --error-30: #871915;
  --error-35: #9a201c;
  --error-40: #ab2d25;
  --error-50: #cd463a;
  --error-60: #ec6052;
  --error-70: #f08f82;
  --error-80: #f4b7ad;
  --error-90: #f9dbd7;
  --error-95: #fcedea;
  --error-98: #fef8f7;
  --error-99: #fefbff;
  --error-100: #ffffff;
}
