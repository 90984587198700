.dark {
  --primary: var(--primary-80);
  --on-primary: var(--primary-20);
  --primary-container: var(--primary-30);
  --on-primary-container: var(--primary-90);
  --secondary: var(--secondary-80);
  --on-secondary: var(--secondary-20);
  --secondary-container: var(--secondary-30);
  --on-secondary-container: var(--secondary-90);
  --tertiary: var(--tertiary-80);
  --on-tertiary: var(--tertiary-20);
  --tertiary-container: var(--tertiary-30);
  --on-tertiary-container: var(--tertiary-90);
  --error: var(--error-80);
  --error-container: var(--error-20);
  --on-error: var(--error-30);
  --on-error-container: var(--error-90);
  --surface-dim: var(--neutral-5);
  --surface: var(--neutral-10);
  --surface-bright: var(--neutral-20);
  --surface-container-lowest: var(--neutral-5);
  --surface-container-low: var(--neutral-10);
  --surface-container: var(--neutral-15);
  --surface-container-high: var(--neutral-20);
  --surface-container-highest: var(--neutral-25);
  --on-surface: var(--neutral-90);
  --on-surface-variant: var(--neutral-variant-90);
  --outline: var(--neutral-variant-60);
  --outline-variant: var(--neutral-variant-30);
  --inverse-surface: var(--neutral-90);
  --inverse-on-surface: var(--neutral-20);
  --inverse-primary: var(--primary-40);
  --scrim: var(--neutral-0);
  --shadow: var(--neeutral-0);
}
