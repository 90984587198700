$alternative-fonts: system-ui, Avenir, Helvetica, Arial, sans-serif;

$inter:
  "Inter",
  #{$alternative-fonts};

:root {
  --headline-large: 700 2.5rem/1.4 #{$inter};
  --headline-regular: 700 2.25rem/1.4 #{$inter};
  --headline-small: 700 2rem/1.4 #{$inter};

  --title-large: 400 1.75rem/1.2 #{$inter};
  --title-regular: 400 1.5rem/1.2 #{$inter};
  --title-small: 400 1.25rem/1.4 #{$inter};

  --body-large: 400 1.125rem/1.1 #{$inter};
  --body-regular: 400 1rem/1.15 #{$inter};
  --body-small: 400 0.875rem/1.4 #{$inter};

  --label-large: 400 0.7rem/1.25 #{$inter};
  --label-regular: 400 0.625rem/1.4 #{$inter};
  --label-small: 400 0.5rem/1.4 #{$inter};
}
