button {
  &.filled {
    --color: var(--primary);
    --on-color: var(--on-primary);

    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 2.5rem;
    min-height: 2.5rem;
    border: none;
    border-radius: 1.25rem;
    font-size: 1rem;
    color: var(--on-color);
    background-color: var(--color);
    transition: 100ms ease;

    &:disabled {
      color: color-mix(in srgb, var(--on-surface) 38%, transparent);
      background-color: color-mix(in srgb, var(--on-surface) 12%, transparent);
      cursor: not-allowed;
    }

    &:enabled {
      cursor: pointer;

      &:hover {
        background-color: color-mix(in srgb, var(--on-color) 10%, var(--color));
      }

      &:active {
        background-color: color-mix(in srgb, var(--on-color) 20%, var(--color));
      }

      &:focus {
        outline-color: var(--outline);
      }
    }

    span {
      color: inherit;
    }
  }

  &.text {
    --color: var(--primary);
    --on-color: var(--on-primary);

    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 2.5rem;
    min-height: 2.5rem;
    border: none;
    border-radius: 1.25rem;
    font-size: 1rem;
    color: var(--color);
    background-color: transparent;
    transition: 100ms ease;

    &:disabled {
      color: color-mix(in srgb, var(--on-surface) 38%, transparent);
      background-color: color-mix(in srgb, var(--on-surface) 12%, transparent);
      cursor: not-allowed;
    }

    &:enabled {
      cursor: pointer;

      &:hover {
        background-color: color-mix(in srgb, var(--color) 10%, transparent);
      }

      &:active {
        background-color: color-mix(in srgb, var(--color) 20%, transparent);
      }

      &:focus {
        outline-color: var(--outline);
      }
    }

    &.selected {
      color: var(--on-color);
      background-color: var(--color);

      &:disabled {
        color: color-mix(in srgb, var(--on-surface) 38%, transparent);
        background-color: color-mix(
          in srgb,
          var(--on-surface) 12%,
          transparent
        );
        cursor: not-allowed;
      }

      &:enabled {
        &:hover {
          background-color: color-mix(
            in srgb,
            var(--on-color) 10%,
            var(--color)
          );
        }

        &:active {
          background-color: color-mix(
            in srgb,
            var(--on-color) 20%,
            var(--color)
          );
        }

        &:focus {
          outline-color: var(--outline);
        }
      }
    }

    span {
      color: inherit;
    }
  }

  &.outlined {
    --color: var(--primary);
    --on-color: var(--on-primary);

    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
    height: 2.5rem;
    min-height: 2.5rem;
    border: 0.0625rem solid var(--color);
    border-radius: 1.25rem;
    font-size: 1rem;
    color: var(--color);
    background-color: transparent;
    transition: 100ms ease;

    &:disabled {
      color: color-mix(in srgb, var(--on-surface) 38%, transparent);
      background-color: color-mix(in srgb, var(--on-surface) 12%, transparent);
      cursor: not-allowed;
    }

    &:enabled {
      cursor: pointer;

      &:hover {
        background-color: color-mix(in srgb, var(--color) 10%, transparent);
      }

      &:active {
        background-color: color-mix(in srgb, var(--color) 20%, transparent);
      }

      &:focus {
        outline-color: var(--outline);
      }
    }

    &.selected {
      color: var(--on-color);
      background-color: var(--color);

      &:disabled {
        color: color-mix(in srgb, var(--on-surface) 38%, transparent);
        background-color: color-mix(
          in srgb,
          var(--on-surface) 12%,
          transparent
        );
        cursor: not-allowed;
      }

      &:enabled {
        &:hover {
          background-color: color-mix(
            in srgb,
            var(--on-color) 10%,
            var(--color)
          );
        }

        &:active {
          background-color: color-mix(
            in srgb,
            var(--on-color) 20%,
            var(--color)
          );
        }

        &:focus {
          outline-color: var(--outline);
        }
      }
    }

    span {
      color: inherit;
    }
  }
}

button.icon-button,
img.icon-button {
  --color: var(--on-surface);
  --on-color: var(--surface);

  display: grid;
  place-content: center;
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
  font-size: 1.5rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  color: var(--color);
  background-color: transparent;
  user-select: none;

  &:hover {
    background-color: color-mix(in srgb, var(--color) 5%, transparent);
  }

  &:active {
    background-color: color-mix(in srgb, var(--color) 10%, transparent);
  }

  &:focus {
    outline-color: var(--outline);
  }

  &.selected {
    color: var(--on-color);
    background-color: var(--color);

    &:disabled {
      color: color-mix(in srgb, var(--on-surface) 38%, transparent);
      background-color: color-mix(in srgb, var(--on-surface) 12%, transparent);
      cursor: not-allowed;
    }

    &:hover {
      background-color: color-mix(in srgb, var(--on-color) 10%, var(--color));
    }

    &:active {
      background-color: color-mix(in srgb, var(--on-color) 20%, var(--color));
    }

    &:focus {
      outline-color: var(--outline);
    }
  }
}

div.markdown {
  * {
    white-space: pre-wrap;
    word-break: break-word;
  }

  ol,
  ul {
    padding-left: 1.5rem;
  }
}
