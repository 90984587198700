@import "./styles/typography.scss";
@import "./styles/theme.scss";

:root {
  font: var(--body-regular);
  font-size: 16px;

  color: var(--on-surface);
  background-color: var(--surface);

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;

  ::-moz-selection {
    color: var(--on-surface);
    background-color: var(--primary-container);
  }

  ::selection {
    color: var(--on-surface);
    background-color: var(--primary-container);
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 0.375rem;
    background-color: transparent;
  }

  app-message {
    .message-container[data-type="user"] {
      ::-moz-selection {
        color: var(--on-primary);
        background-color: var(--primary);
      }

      ::selection {
        color: var(--on-primary);
        background-color: var(--primary);
      }
    }
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--on-primary-container);
  }

  :root {
    scrollbar-color: var(--primary) transparent;
    scrollbar-width: thin;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
label,
select,
dialog {
  color: var(--on-surface);
  font: var(--body-regular);
  text-decoration: none;

  @media (max-width: 520px) {
    font: var(--body-small);
  }
}

a {
  color: var(--primary);
}
