.custom {
  --primary-0: var(--custom-primary-0);
  --primary-5: var(--custom-primary-5);
  --primary-10: var(--custom-primary-10);
  --primary-15: var(--custom-primary-15);
  --primary-20: var(--custom-primary-20);
  --primary-25: var(--custom-primary-25);
  --primary-30: var(--custom-primary-30);
  --primary-35: var(--custom-primary-35);
  --primary-40: var(--custom-primary-40);
  --primary-50: var(--custom-primary-50);
  --primary-60: var(--custom-primary-60);
  --primary-70: var(--custom-primary-70);
  --primary-80: var(--custom-primary-80);
  --primary-90: var(--custom-primary-90);
  --primary-95: var(--custom-primary-95);
  --primary-98: var(--custom-primary-98);
  --primary-99: var(--custom-primary-99);
  --primary-100: var(--custom-primary-100);

  --secondary-0: var(--custom-secondary-0);
  --secondary-5: var(--custom-secondary-5);
  --secondary-10: var(--custom-secondary-10);
  --secondary-15: var(--custom-secondary-15);
  --secondary-20: var(--custom-secondary-20);
  --secondary-25: var(--custom-secondary-25);
  --secondary-30: var(--custom-secondary-30);
  --secondary-35: var(--custom-secondary-35);
  --secondary-40: var(--custom-secondary-40);
  --secondary-50: var(--custom-secondary-50);
  --secondary-60: var(--custom-secondary-60);
  --secondary-70: var(--custom-secondary-70);
  --secondary-80: var(--custom-secondary-80);
  --secondary-90: var(--custom-secondary-90);
  --secondary-95: var(--custom-secondary-95);
  --secondary-98: var(--custom-secondary-98);
  --secondary-99: var(--custom-secondary-99);
  --secondary-100: var(--custom-secondary-100);

  --tertiary-0: var(--custom-tertiary-0);
  --tertiary-5: var(--custom-tertiary-5);
  --tertiary-10: var(--custom-tertiary-10);
  --tertiary-15: var(--custom-tertiary-15);
  --tertiary-20: var(--custom-tertiary-20);
  --tertiary-25: var(--custom-tertiary-25);
  --tertiary-30: var(--custom-tertiary-30);
  --tertiary-35: var(--custom-tertiary-35);
  --tertiary-40: var(--custom-tertiary-40);
  --tertiary-50: var(--custom-tertiary-50);
  --tertiary-60: var(--custom-tertiary-60);
  --tertiary-70: var(--custom-tertiary-70);
  --tertiary-80: var(--custom-tertiary-80);
  --tertiary-90: var(--custom-tertiary-90);
  --tertiary-95: var(--custom-tertiary-95);
  --tertiary-98: var(--custom-tertiary-98);
  --tertiary-99: var(--custom-tertiary-99);
  --tertiary-100: var(--custom-tertiary-100);

  --neutral-0: var(--custom-neutral-0);
  --neutral-5: var(--custom-neutral-5);
  --neutral-10: var(--custom-neutral-10);
  --neutral-15: var(--custom-neutral-15);
  --neutral-20: var(--custom-neutral-20);
  --neutral-25: var(--custom-neutral-25);
  --neutral-30: var(--custom-neutral-30);
  --neutral-35: var(--custom-neutral-35);
  --neutral-40: var(--custom-neutral-40);
  --neutral-50: var(--custom-neutral-50);
  --neutral-60: var(--custom-neutral-60);
  --neutral-70: var(--custom-neutral-70);
  --neutral-80: var(--custom-neutral-80);
  --neutral-90: var(--custom-neutral-90);
  --neutral-95: var(--custom-neutral-95);
  --neutral-98: var(--custom-neutral-98);
  --neutral-99: var(--custom-neutral-99);
  --neutral-100: var(--custom-neutral-100);

  --neutral-variant-0: var(--custom-neutral-variant-0);
  --neutral-variant-5: var(--custom-neutral-variant-5);
  --neutral-variant-10: var(--custom-neutral-variant-10);
  --neutral-variant-15: var(--custom-neutral-variant-15);
  --neutral-variant-20: var(--custom-neutral-variant-20);
  --neutral-variant-25: var(--custom-neutral-variant-25);
  --neutral-variant-30: var(--custom-neutral-variant-30);
  --neutral-variant-35: var(--custom-neutral-variant-35);
  --neutral-variant-40: var(--custom-neutral-variant-40);
  --neutral-variant-50: var(--custom-neutral-variant-50);
  --neutral-variant-60: var(--custom-neutral-variant-60);
  --neutral-variant-70: var(--custom-neutral-variant-70);
  --neutral-variant-80: var(--custom-neutral-variant-80);
  --neutral-variant-90: var(--custom-neutral-variant-90);
  --neutral-variant-95: var(--custom-neutral-variant-95);
  --neutral-variant-98: var(--custom-neutral-variant-98);
  --neutral-variant-99: var(--custom-neutral-variant-99);
  --neutral-variant-100: var(--custom-neutral-variant-100);

  --error-0: var(--custom-error-0);
  --error-5: var(--custom-error-5);
  --error-10: var(--custom-error-10);
  --error-15: var(--custom-error-15);
  --error-20: var(--custom-error-20);
  --error-25: var(--custom-error-25);
  --error-30: var(--custom-error-30);
  --error-35: var(--custom-error-35);
  --error-40: var(--custom-error-40);
  --error-50: var(--custom-error-50);
  --error-60: var(--custom-error-60);
  --error-70: var(--custom-error-70);
  --error-80: var(--custom-error-80);
  --error-90: var(--custom-error-90);
  --error-95: var(--custom-error-95);
  --error-98: var(--custom-error-98);
  --error-99: var(--custom-error-99);
  --error-100: var(--custom-error-100);
}
