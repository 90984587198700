.light {
  --primary: var(--primary-40);
  --on-primary: var(--primary-100);
  --primary-container: var(--primary-90);
  --on-primary-container: var(--primary-10);
  --secondary: var(--secondary-40);
  --on-secondary: var(--secondary-100);
  --secondary-container: var(--secondary-90);
  --on-secondary-container: var(--secondary-10);
  --tertiary: var(--tertiary-40);
  --on-tertiary: var(--tertiary-100);
  --tertiary-container: var(--tertiary-90);
  --on-tertiary-container: var(--tertiary-10);
  --error: var(--error-40);
  --error-container: var(--error-100);
  --on-error: var(--error-90);
  --on-error-container: var(--error-10);
  --surface-dim: var(--neutral-95);
  --surface: var(--neutral-98);
  --surface-bright: var(--neutral-99);
  --surface-container-lowest: var(--neutral-100);
  --surface-container-low: var(--neutral-99);
  --surface-container: var(--neutral-98);
  --surface-container-high: var(--neutral-95);
  --surface-container-highest: var(--neutral-90);
  --on-surface: var(--neutral-10);
  --on-surface-variant: var(--neutral-variant-30);
  --outline: var(--neutral-variant-50);
  --outline-variant: var(--neutral-variant-80);
  --inverse-surface: var(--neutral-20);
  --inverse-on-surface: var(--neutral-95);
  --inverse-primary: var(--primary-90);
  --scrim: var(--neutral-0);
  --shadow: var(--neeutral-0);
}
